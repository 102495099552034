import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { getApiUrl } from '../helpers/rest-utils';
import { HttpClient } from '@angular/common/http';
import { concatMap, map, tap } from 'rxjs/operators';
import { User, UserService } from '../user/user.service';
import { Subscription, SubscriptionPrice, SubscriptionStatus, SubscriptionType } from './subscription';
import { AgentService } from '../agent/agent.service';
import { InternalAnalyticsService } from '../internal-analytics.service';
import { formatSecondsAsMinutes } from '../helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  isPaymentMethodInvalid: boolean = false;
  paymentMethodErrors = [];
  open: Subject<boolean> = new Subject();
  close: Subject<boolean> = new Subject();
  initialize: Subject<boolean> = new Subject();
  reset: Subject<boolean> = new Subject();
  created: Subject<boolean> = new Subject();
  credentials: { publishableKey?: string, clientSecret?: string } = {};
  plans: any;

  constructor(private http: HttpClient, private user: UserService, private agent: AgentService, private analytics: InternalAnalyticsService) {

  }

  get userData(): User {
    return this.user.userData;
  }

  get subscriptionData(): Subscription {
    return this.userData.subscription;
  }

  get minutesRemainingInTrialFormatted(): string {
    return formatSecondsAsMinutes(this.agent.primary?.trialSecondsRemaining);
  }

  get hasMinutesRemainingInTrial(): boolean {
    return !!this.agent.primary?.trialSecondsRemaining;
  }

  get isTrialing(): boolean {
    return this.subscriptionData?.status === SubscriptionStatus.Trialing;
  }

  get isActive(): boolean {
    return this.subscriptionData?.status === SubscriptionStatus.Active;
  }

  get isCanceled(): boolean {
    return this.subscriptionData?.status === SubscriptionStatus.Canceled;
  }

  getBilling(): Observable<{ publishableKey: string, secret: string }> {
    return this.http.get(`${getApiUrl()}billing`)
      .pipe(map((res: any) => res?.data), tap((credentials) => this.credentials = credentials));
  }

  getPlans(): Observable<{ publishableKey: string, secret: string }> {
    return this.http.get(`${getApiUrl()}subscription-plans`)
      .pipe(map((res: any) => res?.data), tap((plans) => this.plans = plans));
  }

  handleSubscription(prices: SubscriptionPrice[], createNewSubscription = false, paymentMethod?: string, couponCode?: string): Observable<any> {
    const meteredPrice = prices.find(price => price.usageType === SubscriptionType.Metered);
    const licensedPrice = prices.find(price => price.usageType === SubscriptionType.Licensed);
    const data: any = {};
    if (paymentMethod) { data.paymentMethod = paymentMethod; }
    data.prices = [{ priceId: meteredPrice.id, type: SubscriptionType.Metered }, { priceId: licensedPrice.id, type: SubscriptionType.Licensed }];
    if (couponCode) { data.couponCode = couponCode; }
    createNewSubscription = createNewSubscription || this.isCanceled;
    return this.http[createNewSubscription ? 'post' : 'put'](`${getApiUrl()}subscriptions/${createNewSubscription ? '' : this.user.subscription.id}`, data, { headers: { 'X-Business-ID': this.user.activeBusiness?.id } })
      .pipe(map((res: any) => res?.data), tap((subscription: Subscription) => {
        const user = this.user.userData;
        user.subscription = subscription;
        this.user.refreshUser(user);
      }), concatMap(() => this.agent.getAgent()));
  }
}

export enum SubscriptionPlan {
  Trial = 'Trial',
  Professional = 'Professional',
  Growth = 'Growth',
  Scale = 'Scale',
}