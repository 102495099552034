import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent } from './account.component';
import { SharedModule } from '../shared/shared.module';
import { AppsModule } from '../apps/apps.module';
import { NgIconsModule } from '@ng-icons/core';
import { heroBell, heroClipboard, heroCreditCard, heroDevicePhoneMobile, heroFolderOpen, heroPaperAirplane, heroTrash, heroUser } from '@ng-icons/heroicons/outline';


@NgModule({
  declarations: [AccountComponent],
  imports: [
    CommonModule,
    AccountRoutingModule,
    SharedModule,
    AppsModule,
    NgIconsModule.withIcons({ heroTrash, heroUser, heroClipboard, heroBell, heroCreditCard, heroFolderOpen, heroDevicePhoneMobile, heroPaperAirplane }),
  ],
  providers: [],
  exports: []
})
export class AccountModule {
}
