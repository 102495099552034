export interface Subscription {
  planName: string;
  status: SubscriptionStatus;
  url: string;
  trialEndsAt: boolean;
  id: string;
  billingUrl: string;
  billingDate: string;
  secondsUsed: number;
  minutesIncluded: number;
  overageCost: number;
  features: Feature[];
  planCost: number;
  isCreditCardAdded: boolean;
}

export interface SubscriptionData {
  paymentMethod: string;
  prices: SubscriptionPrice[];
  couponCode?: string;
}

export enum SubscriptionStatus {
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  Trialing = 'trialing',
  Active = 'active',
  PastDue = 'past_due',
  Canceled = 'canceled',
  Unpaid = 'unpaid',
  Paused = 'paused'
}

export interface SubscriptionPrice {
  id: string;
  currency: string;
  amount: number;
  interval: string;
  usageType: SubscriptionType;
}

export interface SubscriptionPlan {
  id: string;
  name: string;
  description: string | null;
  minutesIncluded: string;
  prices: SubscriptionPrice[];
}

export enum SubscriptionType {
  Metered = 'metered',
  Licensed = 'licensed'
}

export enum Feature {
  Appointments = 'appointment_links',
}