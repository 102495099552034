import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { User, UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class UserResolver {
  constructor(private user: UserService) {
  }

  resolve(): Observable<User> {
    return this.user.getUser();
  }
}
