<ng-container *ngIf="control">
  <ng-container *ngIf="isInputType; then inputContent; else textAreaContent"></ng-container>
</ng-container>
<ng-template #inputContent>
  <div [ngClass]="{'has-prefix': !!prefix, 'has-label': !!labelTranslationKey}"
    class="position-relative text-input-wrapper">
    <input (blur)="handleOnBlur($event)" (focus)="handleOnFocus($event)" [attr.maxLength]="maxLength"
      [autocorrect]="isUrlInputType ? 'off' : 'on'" (keyup.enter)="handleOnEnter()"
      [class]="'rosie-form-control form-control'" [dataHook]="labelTranslationKey" [formControl]="control" [id]="id"
      [min]="min" [ngClass]="{'has-value': hasValue, 'has-tooltip': hasTooltip }"
      [ngStyle]="{'padding-left': inputPaddingLeft}" [type]="type"
      placeholder="{{ placeholderTranslationKey | translate }}" />
    <label #prefixContainer *ngIf="prefix" [ngClass]="{'always-display': alwaysDisplayPrefix}" class="prefix">{{
      prefix
      }}</label>
    <label *ngIf="labelTranslationKey" [for]="id" [ngClass]="'rosie-form-control-label'">
      {{ labelTranslationKey | translate }}
    </label>
    <span *ngIf="hasTooltip" [ngbTooltip]="tooltip" class="icon tooltip-icon position-absolute"
      tooltipClass="tooltip-wrapper status-tooltip"><img src="assets/images/question.svg" /></span>
  </div>
</ng-template>
<ng-template #textAreaContent>
  <div class="rosie-textarea-wrapper"
    [ngClass]="{'has-label': labelTranslationKey, 'with-warning': control?.invalid && control?.touched, 'has-value': control?.value}">
    <textarea (blur)="handleOnBlur($event)" (focus)="handleOnFocus($event)" [attr.maxLength]="maxLength"
      [ngClass]="{'has-label': labelTranslationKey, 'has-value': control?.value}"
      [class]="'rosie-form-control form-control rosie-textarea'" [dataHook]="labelTranslationKey"
      [formControl]="control" [id]="id" [rows]="rows" placeholder="{{ placeholderTranslationKey | translate }}">
    </textarea>
    <label *ngIf="labelTranslationKey" [for]="id" [ngClass]="'rosie-form-control-label'">
      {{ labelTranslationKey | translate }}
    </label>
    <span *ngIf="maxLength" class="character-counter" [ngClass]="{'invalid': control.value?.length > maxLength}">{{
      control.value?.length || 0 }} / {{ maxLength }}</span>
  </div>
</ng-template>