<div class="page-content-wrapper account-wrapper">
  <div class="page-content-wrapper-header">
    <div>
      <div class="heading">{{'ACCOUNT_SECTION.HEADING' | translate}}</div>
      <div class="subheading">{{'ACCOUNT_SECTION.SUBHEADING' | translate}}</div>
    </div>
  </div>
  <div class="section-wrapper">
    <div class="section-header">
      <ng-icon class="section-icon" [name]="'heroClipboard'" [size]="'24px'" [color]="'#822AC6'"
        [strokeWidth]="'2'"></ng-icon>
      <div class="section-heading">{{ 'ACCOUNT_SECTION.ACCOUNT.EMAIL' | translate }}</div>
    </div>
    <div class="section-content-wrapper">
      <span class="section-content">
        <span class="section-content-label">{{ userData?.email}}</span>
        <rosie-button [customClass]="'secondary text-sm'" [translationKey]="'COMMON.LOGOUT'" class=""
          [iconPrefixConfig]="{name: 'heroArrowRightStartOnRectangle', color: '#6C6176', size: '20px', strokeWidth: '2px'}"
          (click)="logout()"></rosie-button>
      </span>
    </div>
  </div>
  <div class="section-wrapper">
    <div class="section-header">
      <ng-icon class="section-icon" [name]="'heroBell'" [size]="'24px'" [color]="'#822AC6'"
        [strokeWidth]="'2'"></ng-icon>
      <div class="section-heading">
        {{ 'ACCOUNT_SECTION.ACCOUNT.CALL_NOTIFICATIONS' | translate }}
      </div>
    </div>
    <div class="section-info">
      <img class="icon" [src]="'assets/images/setup-guide-info.svg'">
      <span class="text">{{'ACCOUNT_SECTION.ACCOUNT.NOTIFICATIONS_DESCRIPTION' |
        translate}}</span>
    </div>
    <div class="section-content-wrapper pb-4">
      <div class="d-flex justify-content-between align-items-start">
        <div class="notifications-wrapper">
          <div class="section-content-label">{{ 'ACCOUNT_SECTION.ACCOUNT.EMAIL_NOTIFICATIONS' | translate }}</div>
        </div>
        <rosie-switch [control]="callNotifications?.get('emailNotificationEnabled')"
          class="notification-switch"></rosie-switch>
      </div>
    </div>
    <div class="section-content-wrapper pt-0" *ngIf="callNotifications?.get('emailNotificationEnabled')?.value">
      <div class="section-action-header" [ngClass]="{'with-items': emailControl?.controls?.length > 0}">
        <div>
          <rosie-text [control]="temporaryEmail" class="header-input"
            [placeholderTranslationKey]="'ACCOUNT_SECTION.ACCOUNT.TYPE_EMAIL'" [lowercaseOnly]="true"></rosie-text>
          <rosie-validation-error [show]="temporaryEmail?.errors?.email && temporaryEmail?.touched"
            [translationKey]="'COMMON.VALIDATION.PATTERN'"
            [translationAttributes]="{value: 'Email'}"></rosie-validation-error>
        </div>
        <rosie-button [customClass]="'action wide text-lg'" [translationKey]="'COMMON.ADD'"
          (click)="addEmail()"></rosie-button>
      </div>
      <div class="section-list-item-wrapper with-action-header" *ngIf="emailControl?.controls?.length > 0">
        <div *ngFor="let email of emailControl?.controls; let last = last; let i = index;" class="section-list-item"
          [ngClass]="{'last': last}">
          <div class="text">{{email.value}}</div>
          <div class="section-list-icons">
            <div class="icon"><ng-icon [name]="'heroPencilSquare'" [color]="'#6C6176'" [size]="'20px'"
                (click)="editNotification(i, CallFinishedNotificationChannel.Email)"></ng-icon>
            </div>
            <div class="icon"><ng-icon [name]="'heroTrash'" [color]="'#6C6176'" [size]="'20px'"
                (click)="removeEmail(i)"></ng-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="separator full my-0"></div>
    <div class="section-content-wrapper">
      <div class="d-flex justify-content-between align-items-start">
        <div class="notifications-wrapper">
          <div class="section-content-label">{{ 'ACCOUNT_SECTION.ACCOUNT.TEXT_NOTIFICATIONS' | translate }}</div>
        </div>
        <rosie-switch [control]=" callNotifications?.get('smsNotificationEnabled')"
          class="notification-switch"></rosie-switch>
      </div>
    </div>
    <div class="section-content-wrapper pt-0" *ngIf="callNotifications?.get('smsNotificationEnabled')?.value">
      <div class="section-action-header" [ngClass]="{'with-items': phoneNumbersControl?.controls?.length > 0}">
        <div><rosie-phone [control]="temporaryPhoneNumber" class="header-input"
            [placeholderTranslationKey]="'ACCOUNT_SECTION.ACCOUNT.TYPE_NUMBER'"></rosie-phone>
          <rosie-validation-error [show]="temporaryPhoneNumber?.errors?.required && temporaryPhoneNumber?.touched"
            [translationAttributes]="{value: 'Phone number'}"
            [translationKey]="'COMMON.VALIDATION.REQUIRED'"></rosie-validation-error>
        </div>
        <rosie-button [customClass]="'action wide text-lg'" [translationKey]="'COMMON.ADD'"
          (click)="addPhoneNumber()"></rosie-button>
      </div>
      <div class="section-list-item-wrapper with-action-header" *ngIf="phoneNumbersControl?.controls?.length > 0">
        <div *ngFor="let number of phoneNumbersControl?.controls; let last = last; let i = index;"
          class="section-list-item" [ngClass]="{'last': last}">
          <div class="text">{{number?.value | phoneNumber}}</div>
          <div class="section-list-icons">
            <div class="icon"><ng-icon [name]="'heroPencilSquare'" [color]="'#6C6176'" [size]="'20px'"
                (click)="editNotification(i, CallFinishedNotificationChannel.SMS)"></ng-icon>
            </div>
            <div class="icon"><ng-icon [name]="'heroTrash'" [color]="'#6C6176'" [size]="'20px'"
                (click)="removePhoneNumber(i)"></ng-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section-wrapper">
    <div class="section-header">
      <ng-icon class="section-icon" [name]="'heroCreditCard'" [size]="'24px'" [color]="'#822AC6'"
        [strokeWidth]="'2'"></ng-icon>
      <div class="section-heading">
        {{ 'ACCOUNT_SECTION.ACCOUNT.SUBSCRIPTION' | translate }}
      </div>
    </div>
    <div class="section-content-wrapper">
      <div class="section-content">
        <div>
          <div class="section-content-label">{{ planName }} Plan</div>
          <div class="plan-details-wrapper section-content-sublabel">
            <ng-container *ngIf="subscription.isTrialing && subscription.hasMinutesRemainingInTrial">
              <ng-container *ngIf="hasCreditCardOnFile">
                {{'SUBSCRIPTION.TRIAL_SUBSCRIPTION' | translate:{value: subscription.minutesRemainingInTrialFormatted,
                planName: subscription.subscriptionData?.planName, planCost: subscription.subscriptionData?.planCost}
                }}
              </ng-container>
              <ng-container *ngIf="!hasCreditCardOnFile">
                {{'SUBSCRIPTION.TRIAL_SUBSCRIPTION_NO_CREDIT_CARD' |
                translate:{value:subscription.minutesRemainingInTrialFormatted} }}
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!subscription.hasMinutesRemainingInTrial && !hasCreditCardOnFile">
              {{'SUBSCRIPTION.TRIAL_SUBCRIPTION_ENDED_NO_CREDIT_CARD' | translate}}
            </ng-container>
            <ng-container *ngIf="subscription.isActive">
              <ul class="pl-5">
                <ng-container *ngIf="subscription.subscriptionData?.minutesIncluded">
                  <li>
                    Up to {{subscription.subscriptionData?.minutesIncluded}} minutes<br>
                </ng-container>
                <ng-container *ngIf="subscription.subscriptionData?.overageCost">
                  <li>
                    Then {{subscription.subscriptionData?.overageCost | rosieCurrency}} per minute
                  </li>
                </ng-container>
                <li *ngFor="let feature of getSelectedPlan()?.features">
                  {{feature}}
                </li>
              </ul>
            </ng-container>
          </div>
          <ng-container *ngIf="subscription.isCanceled">
            <rosie-alert [type]="'error'" [message]="'SUBSCRIPTION.CANCELED_SUBSCRIPTION'"
              [customClass]="'mt-2'"></rosie-alert>
          </ng-container>
        </div>
        <ng-container *ngIf="hasCreditCardOnFile">
          <rosie-button [customClass]="'secondary text-sm'"
            [translationKey]="subscription?.isCanceled ? 'ACCOUNT_SECTION.ACCOUNT.RESUBSCRIBE' :'ACCOUNT_SECTION.ACCOUNT.CHANGE_PLAN'"
            (click)="subscribe()"></rosie-button>
        </ng-container>
      </div>
    </div>
    <div class="separator full my-0"></div>
    <div class="section-content-wrapper">
      <div class="section-content">
        <div class="d-flex align-items-center">
          <div class="section-content-description-label mr-sm">{{'ACCOUNT_SECTION.ACCOUNT.NEED_MORE_MINUTES' |
            translate}}
          </div>
          <rosie-button [customClass]="'secondary text-sm contact-us-intercom-trigger'"
            [translationKey]="'ACCOUNT_SECTION.ACCOUNT.CONTACT_US'"
            [iconSuffixConfig]="{name: 'heroPaperAirplane', color: '#6C6176', size: '20px', strokeWidth: '2px'}"></rosie-button>
        </div>
        <ng-container *ngIf="hasCreditCardOnFile && !subscription.isCanceled">
          <rosie-button [customClass]="'secondary text-sm'" [translationKey]="'ACCOUNT_SECTION.ACCOUNT.MANAGE_BILLING'"
            [iconPrefixConfig]=" {name: 'heroPencilSquare' , size: '20px' , strokeWidth: '2' , color: '#6C6176' }"
            (click)="redirectToBillingPortal()"></rosie-button>
        </ng-container>
        <ng-container *ngIf="!hasCreditCardOnFile">
          <rosie-button (click)="subscribe()" [translationKey]="'ACCOUNT_SECTION.ACCOUNT.SUBSCRIBE'"
            [customClass]="'text-sm slim narrow'"></rosie-button>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="section-wrapper">
    <div class="section-header">
      <ng-icon class="section-icon" [name]="'heroFolderOpen'" [size]="'24px'" [color]="'#822AC6'"
        [strokeWidth]="'2'"></ng-icon>
      <div class="section-heading">
        {{ 'ACCOUNT_SECTION.ACCOUNT.RECORDINGS' | translate }}
      </div>
    </div>
    <div class="section-content-wrapper">
      <div class="section-content">
        <div>
          <div class="section-content-label">{{'ACCOUNT_SECTION.ACCOUNT.CLEAR_RECORDINGS' | translate}}</div>
          <div class="section-content-sublabel">{{'ACCOUNT_SECTION.ACCOUNT.CLEAR_RECORDINGS_DESCRIPTION' | translate}}
          </div>
        </div>
        <rosie-button [customClass]="'secondary text-sm'"
          [translationKey]="'ACCOUNT_SECTION.ACCOUNT.CLEAR_RECORDINGS_ACTION'" class=""
          [iconPrefixConfig]="{name: 'heroTrash', color: '#6C6176', size: '20px', strokeWidth: '2px'}"
          (click)="clearRecordingModal.open()"></rosie-button>
      </div>
    </div>
  </div>
  <div class="section-wrapper">
    <div class="section-header">
      <ng-icon class="section-icon" [name]="'heroDevicePhoneMobile'" [size]="'24px'" [color]="'#822AC6'"
        [strokeWidth]="'2'"></ng-icon>
      <div class="section-heading">
        {{ 'ACCOUNT_SECTION.ACCOUNT.VERIFICATION_CODES' | translate }}
      </div>
    </div>
    <div class="section-content-wrapper">
      <div class="d-flex align-items-md-center justify-content-between gap-2 flex-wrap">
        <div>
          <div class="section-content-label">{{'ACCOUNT_SECTION.ACCOUNT.VERIFICATION_CODES_RECEIVE' | translate}}
          </div>
          <div class="section-content-sublabel">{{'ACCOUNT_SECTION.ACCOUNT.VERIFICATION_CODES_RECEIVE_DESCRIPTION' |
            translate}}
          </div>
        </div>
        <rosie-switch [control]="smsEnabledForm" class="notification-switch"></rosie-switch>
      </div>
    </div>
    <ng-container *ngIf="smsEnabledForm.value">
      <div class="separator full my-0"></div>
      <div class="section-content-wrapper">
        <div class="section-content">
          <div>
            <div class="section-content-description-label">{{'ACCOUNT_SECTION.ACCOUNT.VERIFICATION_CODES_HISTORY' |
              translate}}
            </div>
          </div>
          <rosie-button [customClass]="'secondary text-sm capitalize'"
            [translationKey]="'ACCOUNT_SECTION.ACCOUNT.VIEW_TEXTS'" (click)="smsModal.open()"></rosie-button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<ngx-smart-modal #clearRecordingModal identifier="clearRecordingModal">
  <div class="modal-title with-subtitle">
    {{'ACCOUNT_SECTION.ACCOUNT.CLEAR_ALL_RECORDINGS' | translate}}
  </div>
  <div class="modal-subtitle with-border"></div>
  <div class="modal-content" [innerHTML]="'ACCOUNT_SECTION.ACCOUNT.CLEAR_RECORDINGS_DELETION' | translate">
  </div>
  <div class="modal-action-line with-border">
    <rosie-button (click)="clearRecordingModal.close()" [translationKey]="'COMMON.CANCEL'"
      [customClass]="'secondary d-block'">
    </rosie-button>
    <rosie-button (click)="clearRecordingData()" [customClass]="'danger d-block slim'"
      [inProgress]="isClearRecordingsInProgress" [translationKey]="'COMMON.DELETE'">
    </rosie-button>
  </div>
</ngx-smart-modal>
<ngx-smart-modal #smsModal identifier="smsModal" (onOpen)="fetchSmsList()">
  <div class="modal-title with-subtitle">
    {{'ACCOUNT_SECTION.ACCOUNT.VIEW_TEXTS' | translate}}
  </div>
  <div class="modal-subtitle with-border">
    {{'ACCOUNT_SECTION.ACCOUNT.VIEW_TEXTS_DESCRIPTION' | translate}}
    <span (click)="fetchSmsList()"
      class="text-underline pointer refresh-messages">{{'ACCOUNT_SECTION.ACCOUNT.REFRESH_MESSAGES' |
      translate}}</span>
  </div>
  <div class="modal-content">
    <div class="modal-description mb-0">
      <div *ngFor="let sms of smsList; let first = first;" class="message" [ngClass]="{'first': first}">
        <div class="text">{{sms}}</div>
      </div>
    </div>
  </div>
</ngx-smart-modal>
<ngx-smart-modal #editNotificationModal identifier="editNotificationModal">
  <div class="modal-title with-subtitle">
    {{ selectedNotificationItem?.type === CallFinishedNotificationChannel.Email ? 'Edit email' : 'Edit number' |
    translate}}
  </div>
  <div class="modal-subtitle with-border"></div>
  <div class="modal-content">
    <ng-container *ngIf="selectedNotificationItem?.type === CallFinishedNotificationChannel.Email">
      <rosie-text [control]="selectedNotificationItem.control"
        [placeholderTranslationKey]="'ACCOUNT_SECTION.ACCOUNT.TYPE_EMAIL'" [lowercaseOnly]="true"></rosie-text>
      <rosie-validation-error
        [show]="selectedNotificationItem.control?.errors?.email && selectedNotificationItem.control?.touched"
        [translationKey]="'COMMON.VALIDATION.PATTERN'"
        [translationAttributes]="{value: 'Email'}"></rosie-validation-error>
    </ng-container>
    <ng-container *ngIf="selectedNotificationItem?.type === CallFinishedNotificationChannel.SMS">
      <rosie-phone [control]="selectedNotificationItem.control"
        [placeholderTranslationKey]="'ACCOUNT_SECTION.ACCOUNT.TYPE_NUMBER'"></rosie-phone>
    </ng-container>
  </div>
  <div class="modal-action-line with-border">
    <rosie-button (click)="editNotificationModal.close()" [translationKey]="'COMMON.CANCEL'"
      [customClass]="'secondary narrow'">
    </rosie-button>
    <rosie-button (click)="handleNotificationChangeSave()" [customClass]="'slim narrow'"
      [translationKey]="'COMMON.SAVE'">
    </rosie-button>
  </div>
</ngx-smart-modal>