<div class="action-icon burger-menu" *ngIf="isMobileVersion" (click)="toggleMenu()"
  [ngClass]="{'expanded': isExpanded}">
  <ng-icon [name]="isExpanded ? 'heroXMark' : 'heroBars3'" [color]="'white'"></ng-icon>
</div>
<div class="d-flex flex-column justify-content-start align-items-center nav-sidebar"
  [ngClass]="{'hideable': isMobileVersion, 'expanded': isExpanded}">
  <div class="rosie-logo-sidebar-wrapper">
    <div [routerLink]="['/']" class="rosie-logo-white rosie-logo-sidebar"></div>
  </div>
  <div class="sidebar-business-title">{{businessTitle}}</div>
  <div class="separator full w-100 my-4"></div>
  <ng-container *ngFor="let item of navSidebarItems; let last = last">
    <div class="d-flex sidebar-item align-items-center position-relative" [routerLink]="'/'+getKebabCaseKey(item.key)"
      (mouseenter)="onMouseEnter(item.key)" (mouseleave)="onMouseLeave(item.key)" #rla="routerLinkActive"
      [routerLinkActive]="['active']" (click)="handleSelection()" [ngClass]="{'mb-3': !last}">
      <ng-icon class="sidebar-icon" [name]="getIcon(item.key)"
        [color]="isHovered(item.key) || rla.isActive ? '#FFFFFF' : '#7F728A'"></ng-icon>
      <span>{{ getTranslationKey(item.key) | translate }}</span>
    </div>
  </ng-container>
  <div class="flex-full"></div>
  <div class="usage-status-item" *ngIf="isAgentPhoneNumberVisible">
    <rosie-usage-status></rosie-usage-status>
  </div>
</div>
<ngx-smart-modal #subscriptionModal identifier="subscriptionModal" (onOpenFinished)="handleSubscriptionOpen()"
  [customClass]="hasCreditCardOnFile ? 'subscription-plan-selection' :'subscription-modal'"
  (onAnyCloseEvent)="handleSubscriptionCancel()">
  <rosie-subscription (onCancel)="handleSubscriptionCancel()"></rosie-subscription>
</ngx-smart-modal>